import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
`;

export const Main = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;
  }

  @media (min-width: 481px) and (max-width: 720px) {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;
  }

  @media (min-width: 721px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    flex: 1;

    padding: 24px;
  }

  @media (min-width: 1280px) {
    flex: 1;

    padding: 24px;
  }
`;

export const Title = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    display: flex;

    margin-top: 72px;

    margin-bottom: 12px;

    h1 {
      font-size: 22px;
      font-weight: 600;
      color: var(--font-color);
    }
  }

  @media (min-width: 481px) and (max-width: 720px) {
    display: flex;
    align-items: center;

    margin: 24px 0;

    h1 {
      font-size: 24px;
      font-weight: 600;
      color: var(--font-color);
    }
  }

  @media (min-width: 721px) and (max-width: 1023px) {
    display: flex;
    align-items: center;

    margin: 24px 0;

    h1 {
      font-size: 24px;
      font-weight: 600;
      color: var(--font-color);
    }
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    display: flex;
    align-items: center;

    margin: 24px 0;

    h1 {
      font-size: 24px;
      font-weight: 600;
      color: var(--font-color);
    }
  }

  @media (min-width: 1280px) {
    display: flex;
    align-items: center;

    margin-bottom: 24px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      color: var(--font-color);
    }
  }
`;

export const Icon = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;
  background: var(--primary-color);
  border-radius: 4px;

  margin-right: 16px;
`;

export const TableContainer = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    max-width: calc(100vw - 16px);
  }

  @media (min-width: 481px) and (max-width: 720px) {
    max-width: calc(100vw - 32px);
  }
`;
