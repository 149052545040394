import { makeStyles, createMuiTheme } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: 'var(--primary-color)',
    fontSize: 12,
    color: 'white',
    '&:hover': {
      opacity: '80%',
      backgroundColor: 'var(--primary-color)',
    },
    '&:disabled': { backgroundColor: 'grey', opacity: '25%', color: 'white' },
  },
  syncButton: {
    borderRadius: '50%',
    fontSize: 25,
    color: 'var(--primary-color)',
    '&:hover': {
      animation: '$rotation 2s infinite linear',
    },
  },
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(-359deg)',
    },
  },
  closeSearch: {
    color: 'red',
  },
}));

export const themeMuiTable = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        padding: 25,
        '@media (min-width: 426px) and (max-width: 768px)': {
          minWidth: '100vw',
        },
        '@media (min-width: 769px)': {
          minWidth: '100%',
        },
        '@media (max-width: 320px)': {
          textAlignLast: 'center',
        },
      },
    },
  },
});
