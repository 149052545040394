/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';
import qs from 'qs';
import cognito from './cognito';
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('dotenv').config();

interface Token {
  exp: number;
  iat: number;
  client_id: string;
}

const removeAllTokens = () => {
  localStorage.removeItem('@adas:access_token');
  localStorage.removeItem('@adas:id_token');
};

const adas = axios.create({
  baseURL: process.env.REACT_APP_api_url,
});

adas.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const id_token = localStorage.getItem('@adas:id_token');
  const token: Token = jwt_decode(
    localStorage.getItem('@adas:access_token') as string,
  );
  const exp = token.exp * 1000;
  if (new Date() > new Date(exp)) {
    const data = {
      grant_type: 'refresh_token',
      client_id: token.client_id,
      refresh_token: localStorage.getItem('@adas:refresh_token') as string,
    };
    await cognito
      .post('oauth2/token', qs.stringify(data), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authentication: `Bearer ${localStorage.getItem('@adas:id_token')}`,
        },
      })
      .then(res => {
        removeAllTokens();
        localStorage.setItem('@adas:access_token', res.data.access_token);
        localStorage.setItem('@adas:id_token', res.data.id_token);
        config.headers.Authorization = `Bearer ${res.data.id_token}`;
      })
      .catch(() => {
        localStorage.clear();
        window.location.href = `${process.env.REACT_APP_base_url}/login?response_type=code&client_id=${process.env.REACT_APP_client_id}&redirect_uri=${process.env.REACT_APP_redirect_uri}&scope=openid`;
      });
  } else {
    config.headers.Authorization = `Bearer ${id_token}`;
  }

  config.headers['x-country'] = localStorage.getItem('current-country');

  return config;
});

// adas.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     if (error.response.status === 401) {
//       // removeAllTokens();
//       window.location.href = `${process.env.REACT_APP_base_url}/login?response_type=code&client_id=${process.env.REACT_APP_client_id}&redirect_uri=${process.env.REACT_APP_redirect_uri}&scope=openid`;
//     }
//   },
// );

export default adas;
