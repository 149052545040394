import React, { useState, useRef } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  FormHelperText,
  Modal,
  TextField,
} from '@material-ui/core';
import { LoaderDiv } from '../Loader/styles';
import adas from '../../services/adas';
import { ModalContainer } from '../ModalComment/styles';
import { MessagesEnum } from '../../utils/enum/message.types.enum';

export interface ModalItemMismatchData {
  UUID: string;
  vin: string;
  index: number;
  noVin: boolean;
  licence: string;
}

interface Props {
  itemData: ModalItemMismatchData;
  open: boolean;
  setOpen: (value: boolean) => void;
  setUpdateList: (index: number, message: string, type: MessagesEnum) => void;
}

const ModalLink: React.FC<Props> = ({
  itemData,
  open,
  setOpen,
  setUpdateList,
}) => {
  const textFieldRef = useRef<HTMLInputElement>();
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const jobid = textFieldRef.current?.value;
    if (!jobid) {
      setFormError(`Sorry, Job ID cannot be empty!`);
    } else {
      try {
        if (itemData) {
          const association = {
            UUID: itemData.UUID,
            vin: itemData.vin,
            jobid,
          };
          const { data } = await adas.post('/linkVin', association);
          setFormError('');
          if (data.status === 404) {
            setUpdateList(itemData.index, data.message, MessagesEnum.WARNING);
          } else {
            setUpdateList(itemData.index, data.message, MessagesEnum.SUCCESS);
          }
        }
      } catch (error) {
        setFormError(`Something went wrong`);
      } finally {
        setIsLoading(false);
        setOpen(false);
      }
    }
  };

  const title = itemData.noVin
    ? `Licence ${itemData.licence}`
    : `VIN Number ${itemData.vin}`;

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalContainer>
          <form
            onSubmit={e => handleSubmit(e)}
            style={{ position: 'relative' }}
          >
            {isLoading && (
              <LoaderDiv>
                <CircularProgress
                  size={24}
                  style={{ color: 'var(--primary-color)' }}
                />
              </LoaderDiv>
            )}
            <h4 style={{ marginBottom: 15 }}>{title}</h4>

            <FormControl error fullWidth>
              <TextField
                inputRef={textFieldRef}
                id="manual-override"
                label="JobID to associate with"
                error={formError !== ''}
                size="medium"
                defaultValue=""
                variant="outlined"
              />
              <FormHelperText id="manual-override-error">
                <strong>{formError}</strong>
              </FormHelperText>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                style={{
                  marginTop: 10,
                  backgroundColor: 'var(--primary-color)',
                  color: 'white',
                }}
              >
                Associate
              </Button>
            </FormControl>
          </form>
        </ModalContainer>
      </Fade>
    </Modal>
  );
};

export default ModalLink;
