import styled from 'styled-components';

export const ModalContainer = styled.div`
  background-color: white;
  border: 2px solid #000;
  padding: 10px;
  width: 380px;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 315px;
    form > h3:first-child {
      font-size: 16px;
    }
  }
`;
