import { Tags } from './formatPotentialTable';

interface IData {
  Country: string;
  Evidence: string;
  HellaTimestamp: string;
  HGLicence: string;
  JSONSource: string;
  NoVin?: boolean;
  ResultText: string;
  Sentiment: string;
  Status: string[];
  StatusNumber: number;
  VIN: string;
  Tags?: Tags[];
  UUID: string;
}

interface IStatus {
  [key: string]: number;
  INCREASED_ERROR_CODE: number;
  MISSING_RESULT: number;
}

export interface IDataMisMatchTableData {
  Evidence: string;
  HellaTimestamp: string;
  HGLicence: string;
  JSONSource: string;
  NoVin: boolean;
  Status: IStatus;
  VIN: string;
  Tags?: Tags[];
  UUID: string;
}

export const formatDataMismatchTable = (
  data: IData[],
): IDataMisMatchTableData[] => {
  const aux = data.map((d: IData) => {
    const Status: IStatus = {
      INCREASED_ERROR_CODE: 0,
      MISSING_RESULT: 0,
    };

    if (d.Status) {
      // eslint-disable-next-line array-callback-return
      d.Status.map((item: string) => {
        const status = item;
        Status[status] += 1;
      });
    }

    return {
      VIN: d.VIN,
      NoVin: d.NoVin ? d.NoVin : false,
      HellaTimestamp: d.HellaTimestamp ? d.HellaTimestamp : '',
      HGLicence: d.HGLicence,
      Status,
      Evidence: d.Evidence,
      JSONSource: d.JSONSource,
      Tags: d.Tags ? d.Tags : [],
      UUID: d.UUID,
    };
  });

  return aux;
};
