import styled from 'styled-components';

export const StatusMessage = {
  Error: styled.div`
    background-color: #f44336;
    color: white;
    padding: 5px;
    text-align: center;
  `,
  Warning: styled.div`
    background-color: #ff9800;
    color: white;
    padding: 5px;
    text-align: center;
  `,
  Info: styled.div`
    background-color: #2196f3;
    color: white;
    padding: 5px;
    text-align: center;
  `,
  Success: styled.div`
    background-color: #4caf50;
    color: white;
    padding: 5px;
    text-align: center;
  `,
  Brown: styled.div`
    background-color: #867770;
    color: white;
    padding: 5px;
    text-align: center;
  `,
};
