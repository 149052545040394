export interface Tags {
  meta_data: {
    date: number;
    user: string;
    label: string;
  };
  reason: string;
  tag: string;
}

interface IData {
  Calibration: string;
  Country: string;
  Evidence: string;
  GSHJobID: string;
  GSHTimestamp: string;
  HellaTimestamp: string;
  HGLicence: string;
  ProductsFound: string;
  ResultText: string;
  Sentiment: string;
  Status: string[];
  Tags?: Tags[];
  StatusNumber: number;
  VIN: string;
  UUID: string;
}

interface IStatus {
  [key: string]: number;
  INCREASED_ERROR_CODE: number;
  MISSING_RESULT: number;
}

export interface IPotentialTableData {
  Calibration: string;
  Evidence: string;
  GSHJobID: string;
  GSHTimestamp: string;
  HellaTimestamp: string;
  HGLicence: string;
  ProductsFound: string;
  Status: IStatus;
  StatusCode: number;
  Tags?: Tags[];
  VIN: string;
  UUID: string;
}

export const formatPotentialTable = (data: IData[]): IPotentialTableData[] => {
  const aux = data.map((d: IData) => {
    const Status: IStatus = {
      INCREASED_ERROR_CODE: 0,
      MISSING_RESULT: 0,
    };

    if (d.Status) {
      // eslint-disable-next-line array-callback-return
      d.Status.map((item: string) => {
        const status = item;
        Status[status] += 1;
      });
    }

    return {
      Calibration: d.Calibration ? d.Calibration : '',
      Evidence: d.Evidence,
      GSHJobID: d.GSHJobID,
      GSHTimestamp: d.GSHTimestamp ? d.GSHTimestamp : '',
      HellaTimestamp: d.HellaTimestamp ? d.HellaTimestamp : '',
      HGLicence: d.HGLicence,
      ProductsFound: d.ProductsFound,
      Status,
      Tags: d.Tags,
      StatusCode: d.StatusNumber,
      VIN: d.VIN,
      UUID: d.UUID,
    };
  });

  return aux;
};
