import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Tags } from '../../utils/formatPotentialTable';

interface Props {
  rowData: string[];
  index: number;
}

const ExpandableCommentsTable: React.FC<Props> = ({ rowData, index }) => {
  const colSpan = rowData.length + 1;
  const tags = (rowData[index] as unknown) as Tags[];
  let result = <></>;
  if (tags && tags.length > 0) {
    result = (
      <>
        {tags.map(tag => {
          const noReason = <>&nbsp; manually overwritten &nbsp;</>;
          const reason = (
            <>
              &nbsp; manually overwritten leaving the message &nbsp;
              <strong>{`"${tag.reason}"`}</strong>
              &nbsp;
            </>
          );
          return (
            <TableRow key={tag.meta_data.date}>
              <TableCell colSpan={colSpan}>
                The user&nbsp;
                <strong>{tag.meta_data.user}</strong>
                {!tag.reason ? noReason : reason}
                at&nbsp;
                <strong>
                  {`${new Date(tag.meta_data.date).toLocaleString(['eu-US'])}`}
                </strong>
                &nbsp; tag:&nbsp;
                <strong>{tag.meta_data.label ?? tag.tag}</strong>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  }
  return result;
};

export default ExpandableCommentsTable;
