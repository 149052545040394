import adas from '../services/adas';

const evidenceDownload = async (
  evidence: string,
  vin: string,
): Promise<void> => {
  try {
    const { data } = await adas.post('calibrationsDownload', {
      evidence,
    });
    const a = document.createElement('a');
    if (data && data.download_url) {
      a.href = data.download_url;
      a.setAttribute('download', 'true');
    }
    if (data && data.json_data) {
      const blob = new Blob([data.json_data], {
        type: 'application/json;charset=utf-8;',
      });
      const url = URL.createObjectURL(blob);
      a.href = url;
      a.setAttribute('download', `${vin}.json`);
    }
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    throw new Error(error);
  }
};

export default evidenceDownload;
