import React from 'react';
import { IconButton } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import adas from '../../services/adas';
import { useStyles } from '../UI/material.styles';

interface Props {
  rowData: string[];
  UUID: string;
  type: string;
  tableData: any;
  setTableData: (value: any[]) => void;
  setSuccessMessage: (value: string) => void;
  setErrorMessage: (value: string) => void;
  setLoading: (value: boolean) => void;
}

const CustomSyncButton: React.FC<Props> = ({
  rowData,
  UUID,
  type,
  tableData,
  setTableData,
  setSuccessMessage,
  setErrorMessage,
  setLoading,
}) => {
  const classes = useStyles();

  const handleSyncButton = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    vin: string,
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await adas.post('/TmdRecheck', { vin, UUID });
      if (data.CalibrationType) {
        if (data.CalibrationType === 'None') {
          const filterList = tableData.filter(
            (table: any) => table.VIN !== vin,
          );
          setTableData(filterList);
          setSuccessMessage('Calibration performed');
        } else {
          const findItem = tableData.find((table: any) => table.VIN === vin);
          if (findItem?.Calibration) {
            if (findItem.Calibration !== data.CalibrationType) {
              findItem.Calibration = data.CalibrationType;
              setTableData([...tableData, findItem]);
              setSuccessMessage(
                `The calibration on ${vin} was updated to ${data.CalibrationType}`,
              );
            } else {
              setSuccessMessage(`The calibration on ${vin} is still the same`);
            }
          }
        }
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <span>{type}</span>
      <IconButton
        onClick={e => {
          handleSyncButton(e, rowData[0]);
        }}
      >
        <SyncIcon className={classes.syncButton} />
      </IconButton>
    </>
  );
};

export default CustomSyncButton;
