import { Tags } from './formatPotentialTable';

interface IData {
  AdasSupport: string;
  AdasSupportType: string;
  Calibration: string;
  CalibrationsNumber: number;
  Country: string;
  Evidence: string;
  GSHJobID: string;
  GSHTimestamp: string;
  HellaTimestamp: string;
  HGLicence: string;
  ProductsFound: string;
  ResultText: string;
  Sentiment: string;
  Tags?: Tags[];
  StatusNumber: number;
  VIN: string;
}

export interface ICompletedTableData {
  Calibration: string;
  Evidence: string;
  GSHJobID: string;
  GSHTimestamp: string;
  HellaTimestamp: string;
  HGLicence: string;
  ProductsFound: string;
  Tags?: Tags[];
  VIN: string;
}

export const formatCompletedTable = (data: IData[]): ICompletedTableData[] => {
  const aux = data.map((d: IData) => {
    return {
      Calibration: d.Calibration ? d.Calibration : '',
      Evidence: d.Evidence,
      GSHJobID: d.GSHJobID,
      GSHTimestamp: d.GSHTimestamp ? d.GSHTimestamp : '',
      HGLicence: d.HGLicence,
      HellaTimestamp: d.HellaTimestamp ? d.HellaTimestamp : '',
      ProductsFound: d.ProductsFound,
      Tags: d.Tags,
      VIN: d.VIN,
    };
  });

  return aux;
};
