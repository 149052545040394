import React from 'react';
import DataMismatchTable from '../../components/UI/DataMismatch';
import { Main, Title, Icon, TableContainer } from '../../styles/tablePages';

interface Props {
  country: string;
}

const DataMismatch: React.FC<Props> = ({ country }) => {
  const title = 'Data Mismatch';

  return (
    <Main>
      <Title>
        <Icon />
        <h1>{title}</h1>
      </Title>

      <TableContainer>
        <DataMismatchTable title={title} />
      </TableContainer>
    </Main>
  );
};

export default DataMismatch;
