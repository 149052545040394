import React from 'react';
import { ExpandButton } from 'mui-datatables';

interface Props {
  tags: boolean;
  rest: any;
}

const CustomExpandButton: React.FC<Props> = ({ tags, rest }) => {
  if (!tags) {
    return <div style={{ width: '24px' }} />;
  }
  return (
    <ExpandButton
      areAllRowsExpanded={() => {
        return true;
      }}
      buttonClass="button"
      expandableRowsHeader={false}
      isHeaderCell={false}
      iconClass=""
      iconIndeterminateClass=""
      {...rest}
    />
  );
};

export default CustomExpandButton;
