enum CommentTags {
  ASTECH = 'OK - Astech',
  BOSCH = 'OK - Bosch',
  DEALER = 'OK - Dealer',
  HELLA_GUTMANN = 'OK - Hella Gutmann',
  TEXA = 'OK - Texa',
  DUMMY_CAMERA = 'OK - Dummy Camera',
  TESLA_TOOL = 'OK - Tesla Tool',
  DISCLAIMER = 'Disclaimer',
  ON_HOLD = 'On Hold',
  OK_ERROR_CODE = 'OK – Errorcode read out',
  OK_NO_CALIBRATION = 'OK – No Recalibration',
  OK_DATA_GODKENDT = 'Ok - Westpoint data godkendt',
  OK_TMD_INGEN = 'Ok - TMD ingen kalibrering',
}

export default CommentTags;
