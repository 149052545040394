import React, { useState, useRef, useEffect } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import { LoaderDiv } from '../Loader/styles';
import adas from '../../services/adas';
import { ModalContainer } from './styles';
import { Tags } from '../../utils/formatPotentialTable';
import { useAuth } from '../../hooks/auth';
import { ITokenPayload } from '../Header/index';
import CommentTags from '../../utils/enum/comment.tags.enum';

interface TagsList {
  value: string;
  text: string;
}

export interface ModalItemData {
  UUID: string;
  vin: string;
  index: number;
}

const tagsList: TagsList[] = [
  {
    value: CommentTags.ASTECH,
    text: CommentTags.ASTECH,
  },
  {
    value: CommentTags.BOSCH,
    text: CommentTags.BOSCH,
  },
  {
    value: CommentTags.DEALER,
    text: CommentTags.DEALER,
  },
  {
    value: CommentTags.DUMMY_CAMERA,
    text: CommentTags.DUMMY_CAMERA,
  },
  {
    value: CommentTags.HELLA_GUTMANN,
    text: CommentTags.HELLA_GUTMANN,
  },
  {
    value: CommentTags.TESLA_TOOL,
    text: CommentTags.TESLA_TOOL,
  },
  {
    value: CommentTags.TEXA,
    text: CommentTags.TEXA,
  },
  {
    value: CommentTags.OK_DATA_GODKENDT,
    text: CommentTags.OK_DATA_GODKENDT,
  },
  {
    value: CommentTags.OK_TMD_INGEN,
    text: CommentTags.OK_TMD_INGEN,
  },
  {
    value: CommentTags.DISCLAIMER,
    text: CommentTags.DISCLAIMER,
  },
  {
    value: 'On Hold',
    text: 'On Hold',
  },
];

interface Props {
  itemData: ModalItemData;
  open: boolean;
  setOverrideList: (value: string) => void;
  setOpen: (value: boolean) => void;
  setUpdateList: (value: Tags, index: number) => void;
}

const ModalComment: React.FC<Props> = ({
  itemData,
  open,
  setOverrideList,
  setOpen,
  setUpdateList,
}) => {
  const { data } = useAuth();
  const textFieldRef = useRef<HTMLInputElement>();
  const [formError, setFormError] = useState('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [tagLabel, setTagLabel] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const decodedToken: ITokenPayload = jwt_decode(data.id_token);
    setUserEmail(decodedToken.email);
  }, [data]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const reason = textFieldRef.current?.value;
    if (
      (tagLabel === CommentTags.ON_HOLD ||
        tagLabel === CommentTags.DISCLAIMER) &&
      !reason
    ) {
      setFormError(`Sorry, reason cannot be empty!`);
      setIsLoading(false);
    } else {
      try {
        if (itemData) {
          const tag = tagLabel === CommentTags.ON_HOLD ? 'ON_HOLD' : 'SOLVED';

          const tabLab = tagsList.find(t => t.value === tagLabel);

          const updateData = {
            UUID: itemData.UUID,
            vin: itemData.vin,
            tag,
            reason,
            tagLabel: tabLab?.text,
          };
          await adas.post('/updateItem', updateData);
          setFormError('');
          if (tagLabel !== CommentTags.ON_HOLD) {
            setOverrideList(itemData.vin);
          }
          const tags: Tags = {
            meta_data: {
              date: new Date().getTime(),
              user: userEmail,
              label: tabLab?.text || '',
            },
            reason: reason || '',
            tag,
          };
          setUpdateList(tags, itemData.index);
          setOpen(false);
          setTagLabel('');
        }
      } catch (error) {
        setFormError(`Something went wrong`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalContainer>
          <form
            onSubmit={e => handleSubmit(e)}
            style={{ position: 'relative' }}
          >
            {isLoading && (
              <LoaderDiv>
                <CircularProgress
                  size={24}
                  style={{ color: 'var(--primary-color)' }}
                />
              </LoaderDiv>
            )}
            <h3 style={{ marginBottom: 15 }}>{`VIN Number ${itemData.vin}`}</h3>
            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginBottom: 15 }}
            >
              <InputLabel id="tag-select">Tag</InputLabel>
              <Select
                labelId="tag-select"
                value={tagLabel}
                onChange={e => setTagLabel(e.target.value as string)}
                label="Tag"
              >
                {tagsList.map(tagl => (
                  <MenuItem value={tagl.value} key={tagl.value}>
                    {tagl.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl error fullWidth>
              <TextField
                inputRef={textFieldRef}
                id="manual-override"
                label="Why is this item being overwritten?"
                multiline
                fullWidth
                error={formError !== ''}
                rows={5}
                size="medium"
                defaultValue=""
                variant="outlined"
              />
              <FormHelperText id="manual-override-error">
                <strong>{formError}</strong>
              </FormHelperText>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                style={{
                  marginTop: 10,
                  backgroundColor: 'var(--primary-color)',
                  color: 'white',
                }}
              >
                Send
              </Button>
            </FormControl>
          </form>
        </ModalContainer>
      </Fade>
    </Modal>
  );
};

export default ModalComment;
