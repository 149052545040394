import styled from 'styled-components';

export const MissedTableContainer = styled.div`
  .MuiTableCell-root {
    @media (max-width: 959px) {
      padding: 12px;
    }
    padding: 8px;
  }
`;
