import React from 'react';
import PotentialIssuesTable from '../../components/UI/PotentialIssuesTable';
import { Main, Title, Icon, TableContainer } from '../../styles/tablePages';

interface Props {
  country: string;
}

const PotentialIssues: React.FC<Props> = ({ country }) => {
  const title = 'Potential Issues';

  return (
    <Main>
      <Title>
        <Icon />
        <h1>{title}</h1>
      </Title>

      <TableContainer>
        <PotentialIssuesTable title={title} />
      </TableContainer>
    </Main>
  );
};

export default PotentialIssues;
