import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  closeSearch: {
    color: 'red',
  },
}));

export const SearchContainer = styled.div`
  width: 280px;
`;
