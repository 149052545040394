import styled from 'styled-components';
import { transparentize } from 'polished';

interface DropdownProps {
  show: boolean;
}

interface UserMenuProps {
  show: boolean;
}

interface ListProps {
  show: boolean;
}

export const Container = styled.div<ListProps>`
  position: relative;

  background: #ffffff;

  min-height: 102px;
  width: 100%;

  @media (min-width: 320px) and (max-width: 480px) {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: ${({ show }) => (show ? 'none' : 'center')};
    justify-content: center;
  }

  @media (min-width: 481px) and (max-width: 1279px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 36px;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 36px;
  }

  @media (min-width: 1280px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 74px;
  }
`;

export const FlagLoginContainer = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 320px) and (max-width: 480px) {
    display: contents;
  }
`;

export const MenuLogoContainer = styled.div<ListProps>`
  align-items: center;
  display: inline-flex;
  align-self: ${({ show }) => (show ? 'center' : 'none')};
`;

export const MenuButton = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: 25px;
    text-align-last: center;
  }
  cursor: pointer;
  position: relative;
`;

export const List = styled.div<ListProps>`
  @media (min-width: 481px) {
    position: absolute;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    top: 28px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
  }

  z-index: ${({ show }) => (show ? 10000 : 'none')};
  background: ${({ show }) => (show ? '#fdfdfd' : 'none')};
  display: ${({ show }) => (show ? 'flex' : 'none')};

  flex-direction: column;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 240px;

  a {
    color: var(--font-color);
    margin-bottom: 6px;
  }

  span {
    margin-right: 5px;
  }
`;

export const User = styled.div`
  position: relative;
  display: flex;
  justify-self: end;
  align-items: center;
  justify-content: space-between;
  color: var(--font-color);
  margin-left: 20px;
  transition: color 0.3s;
  h1 {
    margin: 0 6px 0 10px;
    font-size: 16px;
    font-weight: 300;
    line-height: 60px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const UserMenu = styled.div<UserMenuProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  right: 0px;
  top: 48px;
  background: var(--navigation-background);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 16px;
  &:hover {
    background: ${transparentize(0.9, '#3BCCF6')};
  }
`;

export const Picture = styled.div`
  width: 40px;
  height: 40px;
  background: blueviolet;
  border-radius: 50%;
`;

export const Country = styled.div`
  position: relative;
  z-index: 1000;

  @media (min-width: 320px) and (max-width: 480px) {
    max-widht: 100px;
    margin-top: 15px;
  }
`;

export const Select = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 4px 8px;
  span {
    color: var(--font-color);
    font-weight: 500;
    font-size: 14px;
    margin-right: 8px;
  }
`;

export const Dropdown = styled.div<DropdownProps>`
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 36px;
  border-radius: 4px;
  span {
    margin-right: 8px;
  }
`;

export const Countries = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  span {
    font-size: 16px;
    font-weight: 300;
    color: var(--font-color);
    margin-right: 16px;
  }
  &:hover {
    background: ${transparentize(0.9, '#3BCCF6')};
  }
`;
