import React, { useState } from 'react';
import { Button, FormControl, IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles, SearchContainer } from './styles';
import TableNamesEnum from '../../utils/enum/tables.enum';
import adas from '../../services/adas';

interface Props {
  tableName: TableNamesEnum;
  setFetch: (value: boolean) => void;
  hideSearch: () => void;
  setTableData: (value: []) => void;
  handleLoading: (value: boolean) => void;
  handleErrorMessage: (message: string) => void;
}

const TableSearch: React.FC<Props> = ({
  tableName,
  setFetch,
  hideSearch,
  setTableData,
  handleLoading,
  handleErrorMessage,
}) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');

  const searchEvent = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    handleLoading(true);
    try {
      const { data } = await adas.post('/searchVin', {
        vin: searchText,
        productNumber: searchText,
        tableName,
      });
      setTableData(data);
      handleErrorMessage('');
    } catch (error) {
      handleErrorMessage(error.message);
    } finally {
      handleLoading(false);
    }
  };

  return (
    <>
      <FormControl>
        <SearchContainer>
          <TextField
            id="search-table"
            label="VIN/Partnumber/JobID/Licence"
            size="medium"
            fullWidth
            defaultValue={searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchText(e.target.value);
            }}
          />
        </SearchContainer>
      </FormControl>
      <Button
        type="submit"
        variant="contained"
        style={{
          marginLeft: 10,
          backgroundColor: 'var(--primary-color)',
          color: 'white',
        }}
        onClick={e => {
          searchEvent(e);
        }}
      >
        Search
      </Button>
      <IconButton
        onClick={() => {
          hideSearch();
          setTableData([]);
          setFetch(true);
        }}
      >
        <CloseIcon className={classes.closeSearch} />
      </IconButton>
    </>
  );
};

export default TableSearch;
