const commonOptions = {
  filter: false,
  sort: true,
  print: false,
  rowsPerPageOptions: undefined,
  download: true,
  selectableRowsHideCheckboxes: true,
  expandableRows: true,
  expandableRowsOnClick: true,
  expandableRowsHeader: false,
};

export default commonOptions;
