import { Tags } from './formatPotentialTable';

interface IData {
  Country: string;
  GSHJobID: string;
  GSHTimestamp: string;
  HellaTimestamp: string | null;
  Calibration: string;
  NeedCalibration: boolean;
  ProductsFound: boolean;
  Tags?: Tags[];
  VIN: string;
  UUID: string;
}

export interface IMissedTableData {
  VIN: string;
  GSHJobID: string;
  GSHTimestamp: string;
  HellaTimestamp: string | null;
  Calibration: string;
  Tags?: Tags[];
  ProductsFound: boolean;
  UUID: string;
}

export const formatMissedTable = (data: IData[]): IMissedTableData[] => {
  const aux = data.map((d: IData) => {
    return {
      Calibration: d.Calibration ? d.Calibration : '',
      GSHJobID: d.GSHJobID,
      GSHTimestamp: d.GSHTimestamp ? d.GSHTimestamp : '',
      HellaTimestamp: d.HellaTimestamp ? d.HellaTimestamp : '',
      ProductsFound: d.ProductsFound,
      Tags: d.Tags,
      VIN: d.VIN,
      UUID: d.UUID,
    };
  });
  return aux;
};
