import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import MissCalibration from '../pages/MissCalibration';
import PotentialIssues from '../pages/PotentialIssues';
import SignIn from '../pages/SignIn';
import CompletedRecalibrations from '../pages/CompletedRecalibrations';
import DataMismatch from '../pages/DataMismatch';

import Header from '../components/Header';

import { Container, Content, TableContainer } from '../styles/tablePages';

const Routes: React.FC = () => {
  const [country, setCountry] = useState<string>('DK');

  useEffect(() => {
    localStorage.setItem('current-country', country);
  }, [country]);

  return (
    <Switch>
      <Route path="/login" exact component={SignIn} />
      <Container>
        <Content>
          <Header country={country} setCountry={setCountry} />

          <TableContainer>
            <Route
              path="/"
              exact
              component={() => <MissCalibration country={country} />}
              isPrivate
            />
            <Route
              path="/issues"
              component={() => <PotentialIssues country={country} />}
              isPrivate
            />
            <Route
              path="/completed"
              component={() => <CompletedRecalibrations country={country} />}
              isPrivate
            />
            <Route
              path="/mismatch"
              component={() => <DataMismatch country={country} />}
              isPrivate
            />
          </TableContainer>
        </Content>
      </Container>
    </Switch>
  );
};

export default Routes;
