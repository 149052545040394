import { Button, CircularProgress, MuiThemeProvider } from '@material-ui/core';
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableTextLabels,
} from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import adas from '../../../services/adas';
import { StatusMessage } from '../../../styles/usefull';
import { MessagesEnum } from '../../../utils/enum/message.types.enum';
import TableNamesEnum from '../../../utils/enum/tables.enum';
import {
  formatDataMismatchTable,
  IDataMisMatchTableData,
} from '../../../utils/formatDataMismatchTable';
import tableCsvDownload from '../../../utils/tableCsvDownload';
import {
  CloseAlertButton,
  GreenAlert,
  RedAlert,
  WarningAlert,
} from '../../Alerts/styles';
import DownloadEvidence from '../../DownloadEvidence';
import { LoaderDiv } from '../../Loader/styles';
import ModalCommentMismatch, {
  ModalItemData as ModelItemDataComment,
} from '../../ModalCommentMismatch';
import ModalLink, { ModalItemMismatchData } from '../../ModalLink';
import CustomTableFooter from '../../TableFooter';
import TableSearch from '../../TableSearch';
import commonOptions from '../commonOptions';
import { themeMuiTable, useStyles } from '../material.styles';
import { IPaginationKey } from '../PotentialIssuesTable';
import { Tags } from '../../../utils/formatPotentialTable';
import ExpandableCommentsTable from '../../ExpandableCommentsTable';
import CustomExpandButton from '../../CustomExpandButton';

const cols: MUIDataTableColumnDef[] = [
  {
    label: 'VIN Number',
    name: 'VIN',
    options: {
      filter: true,
      print: true,
      customBodyRender: (value, { rowData }) => {
        const noVin = rowData[4];
        if (noVin) {
          return `---No Vin---`;
        }
        return value;
      },
    },
  },
  {
    label: 'Hella Timestamp',
    name: 'HellaTimestamp',
    options: {
      filter: false,
      print: true,
      customBodyRender: value => {
        if (value) {
          const time = new Date(+value);

          return `${time.toLocaleString(['eu-US'])}`;
        }
        return 'Null';
      },
    },
  },
  {
    label: 'HG Licence',
    name: 'HGLicence',
    options: {
      filter: false,
      print: true,
      customBodyRender: value => {
        if (!value) {
          return `---`;
        }
        return value;
      },
    },
  },
  {
    name: 'Status',
    label: 'Status',
    options: {
      filter: true,
      print: true,
      customBodyRender: value => {
        let status2 = <></>;
        if (value.MISSING_RESULT !== 0) {
          status2 = (
            <StatusMessage.Warning key={`${uuidv4()}`}>
              {`${value.MISSING_RESULT} - MISSING RESULT`}
            </StatusMessage.Warning>
          );
        }

        return <>{status2}</>;
      },
    },
  },
  {
    name: 'NoVin',
    options: {
      display: false,
      viewColumns: false,
      filter: false,
    },
  },
  {
    name: 'Tags',
    options: {
      display: false,
      viewColumns: false,
      filter: false,
    },
  },
  {
    name: 'UUID',
    options: {
      display: false,
      viewColumns: false,
      filter: false,
    },
  },
];

interface Props {
  title: string;
}

const DataMismatchTable: React.FC<Props> = () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState<IDataMisMatchTableData[]>([]);
  const [pagination, setPagination] = useState<IPaginationKey>(
    {} as IPaginationKey,
  );
  const [totalItems, setTotalItems] = useState(0);
  const [finalLoad, setFinalLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [fetch, setFetch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [itemData, setItemData] = useState<ModalItemMismatchData>(
    {} as ModalItemMismatchData,
  );
  const [itemDataUpdate, setItemDataUpdate] = useState<ModelItemDataComment>(
    {} as ModelItemDataComment,
  );
  const [pageState, setPageState] = useState(0);
  const [overrideList, setOverrideList] = useState<string[]>([]);

  const height = window.innerHeight;
  const trHeight = 100;
  const table = height - 270 - 56 - 53;
  const tableFinalHeight = table < trHeight ? trHeight : table;
  const rowsPerPage =
    Math.floor(tableFinalHeight / trHeight) - 1 !== 0
      ? Math.floor(tableFinalHeight / trHeight)
      : 2;

  const handlePageState = (value: number) => {
    setPageState(value);
  };

  // const handleSuccessMessage = (value: string) => {
  //   setSuccessMessage(value);
  // };

  // const handleWarningMessage = (value: string) => {
  //   setWarningMessage(value);
  // };

  const handleItemUpdate = (
    index: number,
    message: string,
    type: MessagesEnum,
  ) => {
    if (type === MessagesEnum.SUCCESS) {
      tableData.splice(index, 1);
      setSuccessMessage(message);
    } else if (type === MessagesEnum.WARNING) {
      setWarningMessage(message);
    } else {
      setErrorMessage(message);
    }
  };

  const handleErrorMessage = (value: string) => {
    setErrorMessage(value);
  };

  const handleOpen = (value: boolean) => {
    setOpen(value);
  };

  const handleOpenAction = (value: boolean) => {
    setOpenAction(value);
  };

  const handleLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const handleFetch = (value: boolean) => {
    if (finalLoad === false) {
      setFetch(value);
    }
  };

  const handleAdasSearch = async (data: []) => {
    if (data.length !== 0) {
      const tableSearch = formatDataMismatchTable(data);
      setTableData([...tableSearch]);
    } else {
      setTableData([]);
    }
  };

  const handleCsvDownload = async (tableName: string) => {
    setIsLoading(true);
    try {
      await tableCsvDownload(tableName);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  };

  const handleOverrideList = (value: string) => {
    setOverrideList([...overrideList, value]);
  };

  useEffect(() => {
    const getData = async () => {
      if (finalLoad === false && fetch) {
        setIsLoading(true);
        const params =
          Object.keys(pagination).length !== 0
            ? { PaginationKey: pagination }
            : {};
        try {
          const { data } = await adas.post('/DataMismatch', params);
          setTotalItems(data.TotalItems ? data.TotalItems : totalItems);
          const aux = formatDataMismatchTable(data.Items);
          const array = [...tableData, ...aux];
          if (data.PaginationKey) {
            setPagination(data.PaginationKey);
          } else {
            setPagination({} as IPaginationKey);
            setFinalLoad(true);
          }
          setTableData(array);
        } catch (err: any) {
          setTableData([]);
          setFinalLoad(true);
          if (err.response && err.response.status === 401) {
            setIsAuthorized(false);
            setErrorMessage('You are not authorized to perform this action');
          }
        } finally {
          setFetch(false);
          setIsLoading(false);
        }
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const handleItemUpdateComment = (value: Tags, index: number) => {
    const itemsList = [...tableData];
    let item = tableData[index];
    if (item && item.Tags) {
      item.Tags.push(value);
    } else {
      item = {
        ...item,
        Tags: [{ ...value }],
      };
    }
    itemsList[index] = item;
    setTableData([...itemsList]);
    if (value.tag === 'SOLVED') {
      setSuccessMessage(`${item.VIN} successfully marked as solved`);
    } else {
      setSuccessMessage(`Successfully add comment on VIN ${item.VIN}`);
    }
  };

  const components = {
    ExpandButton({ dataIndex, ...rest }: { dataIndex: number }) {
      const row = tableData[dataIndex];
      const tags = !!(row && row.Tags && row.Tags.length > 0);
      return <CustomExpandButton tags={tags} rest={rest} />;
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      {isLoading && (
        <LoaderDiv>
          <CircularProgress
            size={24}
            style={{ color: 'var(--primary-color)' }}
          />
        </LoaderDiv>
      )}
      {errorMessage !== '' && (
        <RedAlert>
          <CloseAlertButton onClick={() => setErrorMessage('')}>
            &times;
          </CloseAlertButton>
          {errorMessage}
        </RedAlert>
      )}
      {successMessage !== '' && (
        <GreenAlert>
          <CloseAlertButton onClick={() => setSuccessMessage('')}>
            &times;
          </CloseAlertButton>
          {successMessage}
        </GreenAlert>
      )}
      {warningMessage !== '' && (
        <WarningAlert>
          <CloseAlertButton onClick={() => setWarningMessage('')}>
            &times;
          </CloseAlertButton>
          {warningMessage}
        </WarningAlert>
      )}
      <ModalLink
        open={open}
        setOpen={handleOpen}
        itemData={itemData}
        setUpdateList={handleItemUpdate}
      />
      <ModalCommentMismatch
        open={openAction}
        setOpen={handleOpenAction}
        itemData={itemDataUpdate}
        setOverrideList={handleOverrideList}
        setUpdateList={handleItemUpdateComment}
      />
      {isAuthorized && (
        <MuiThemeProvider theme={themeMuiTable}>
          <MUIDataTable
            columns={[
              ...cols,
              {
                label: 'Download',
                name: 'Download',
                options: {
                  filter: false,
                  print: false,
                  download: false,
                  customBodyRender: (_, { rowData }) => {
                    return (
                      <DownloadEvidence
                        rowData={rowData}
                        tableData={tableData}
                        setErrorMessage={handleErrorMessage}
                        setIsLoading={handleLoading}
                      />
                    );
                  },
                },
              },
              {
                label: 'Link',
                name: 'Link',
                options: {
                  filter: false,
                  print: false,
                  download: false,
                  customBodyRender: (_, { rowData }) => {
                    const vin = rowData[0];
                    const UUID = rowData[6];
                    return (
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                          const index = tableData.findIndex(
                            data => data.VIN === vin,
                          );
                          setItemData({
                            UUID,
                            vin,
                            index,
                            noVin: rowData[4],
                            licence: rowData[2],
                          });
                          handleOpen(true);
                        }}
                      >
                        Link
                      </Button>
                    );
                  },
                },
              },
              {
                label: 'Action',
                name: 'Action',
                options: {
                  filter: false,
                  print: false,
                  download: false,
                  customBodyRender: (_, { rowData }) => {
                    const vin = rowData[0];
                    const UUID = rowData[6];
                    return (
                      <Button
                        onClick={() => {
                          handleOpenAction(true);
                          const idx = tableData.findIndex(
                            data => data.UUID === UUID,
                          );

                          const data = {
                            UUID,
                            vin,
                            index: idx,
                            noVin: rowData[4],
                            licence: rowData[2],
                          };
                          setItemDataUpdate(data);
                        }}
                        disabled={
                          !!overrideList.find(override => override === vin)
                        }
                        variant="contained"
                        className={classes.button}
                      >
                        {overrideList.find(override => override === vin)
                          ? 'Solved'
                          : 'Add comment'}
                      </Button>
                    );
                  },
                },
              },
            ]}
            data={tableData}
            title=""
            components={components}
            options={{
              ...commonOptions,
              count: totalItems,
              tableBodyMaxHeight: `${tableFinalHeight}px`,
              rowsPerPage,
              responsive: 'vertical',
              renderExpandableRow: (rowData: string[]) => {
                return <ExpandableCommentsTable rowData={rowData} index={5} />;
              },
              customSearchRender: (searchText, _, hideSearch) => {
                return (
                  <TableSearch
                    tableName={TableNamesEnum.DataMismatch}
                    setFetch={handleFetch}
                    hideSearch={hideSearch}
                    setTableData={handleAdasSearch}
                    handleLoading={handleLoading}
                    handleErrorMessage={handleErrorMessage}
                  />
                );
              },
              customFooter: (
                rowCount: number,
                page: number,
                _: number,
                changeRowsPerPage: (pageKey: React.Key) => void,
                changePage: (newPage: number) => void,
                textLabels: Partial<MUIDataTableTextLabels>,
              ) => {
                return (
                  <CustomTableFooter
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    pageS={pageState}
                    tableDataLength={tableData.length}
                    textLabels={textLabels}
                    setFetch={handleFetch}
                    setPageState={handlePageState}
                    onChange={changePage}
                  />
                );
              },
              onDownload: () => {
                handleCsvDownload(TableNamesEnum.DataMismatch);
                return false;
              },
            }}
          />
        </MuiThemeProvider>
      )}
    </div>
  );
};

export default DataMismatchTable;
