import axios from 'axios';
import adas from '../services/adas';

const tableCsvDownload = async (tableName: string): Promise<void> => {
  try {
    let csvContent: string;

    if (tableName === 'CompletedCalibrations') {
      // Get obj pre-signed url
      const { data } = await adas.get('/CompletedCalibrations');

      // Get obj using pre-signed url from previous step
      csvContent = await (await axios.get(data.download_url)).data;
    } else {
      const { data } = await adas.post('/DownloadCsv', { table: tableName });

      csvContent = data;
    }

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', `${tableName}.csv`);
    a.click();
  } catch (error: any) {
    throw new Error(error);
  }
};

export default tableCsvDownload;
