import React, { useState, useEffect } from 'react';
import { CircularProgress, MuiThemeProvider } from '@material-ui/core';
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableTextLabels,
} from 'mui-datatables';
import adas from '../../../services/adas';
import TableNamesEnum from '../../../utils/enum/tables.enum';
import tableCsvDownload from '../../../utils/tableCsvDownload';
import { CloseAlertButton, GreenAlert, RedAlert } from '../../Alerts/styles';
import { LoaderDiv } from '../../Loader/styles';
import CustomTableFooter from '../../TableFooter';
import TableSearch from '../../TableSearch';
import { themeMuiTable } from '../material.styles';
import { dateFormatOptions } from '../MissedRecalibrationsTable/index';
import {
  formatCompletedTable,
  ICompletedTableData,
} from '../../../utils/formatCompletedTable';
import tableAutoSize from '../../../utils/tableAutoSize';
import ExpandableCommentsTable from '../../ExpandableCommentsTable';
import CustomExpandButton from '../../CustomExpandButton';
import commonOptions from '../commonOptions';
import DownloadEvidence from '../../DownloadEvidence';

const cols: MUIDataTableColumnDef[] = [
  {
    label: 'VIN Number',
    name: 'VIN',
    options: {
      filter: true,
      print: true,
    },
  },
  {
    label: 'Product Number',
    name: 'ProductsFound',
    options: {
      filter: true,
      print: true,
    },
  },
  {
    label: 'Job Date',
    name: 'GSHTimestamp',
    options: {
      filter: false,
      print: true,
      customBodyRender: value => {
        if (value) {
          const time = new Date(+value);

          return time.toLocaleString(['eu-US'], dateFormatOptions);
        }
        return 'Null';
      },
    },
  },
  {
    name: 'GSHJobID',
    label: 'Job ID',
    options: {
      filter: true,
      print: true,
      customBodyRender: value => {
        if (!value) {
          return `---`;
        }
        return value;
      },
    },
  },
  {
    label: 'Hella Timestamp',
    name: 'HellaTimestamp',
    options: {
      filter: false,
      print: true,
      customBodyRender: value => {
        if (value) {
          const time = new Date(+value);

          return `${time.toLocaleString(['eu-US'])}`;
        }
        return 'Null';
      },
    },
  },
  {
    label: 'HG Calibration Type',
    name: 'Calibration',
    options: {
      filter: false,
      print: true,
      customBodyRender: value => {
        if (value !== 'None') {
          return value;
        }
        return '---';
      },
    },
  },
  {
    label: 'HG Licence',
    name: 'HGLicence',
    options: {
      filter: false,
      print: true,
      customBodyRender: value => {
        if (!value) {
          return `---`;
        }
        return value;
      },
    },
  },
  {
    name: 'Tags',
    options: {
      display: false,
      viewColumns: false,
      filter: false,
    },
  },
];

export interface IPaginationKey {
  Country: string;
  StatusNumber: number;
  VIN: string;
}

interface Props {
  title: string;
}

const CompletedRecalibrationsTable: React.FC<Props> = () => {
  const [tableData, setTableData] = useState<ICompletedTableData[]>([]);
  const [pagination, setPagination] = useState<IPaginationKey>(
    {} as IPaginationKey,
  );
  const [finalLoad, setFinalLoad] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pageState, setPageState] = useState(0);
  const [fetch, setFetch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(true);

  const height = window.innerHeight;
  const tr = document.getElementsByClassName('MuiTableRow-root')[1];
  const { tableFinalHeight, rowsPerPage } = tableAutoSize(height, tr);

  const handleErrorMessage = (value: string) => {
    setErrorMessage(value);
  };

  const handleLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const handleFetch = (value: boolean) => {
    if (finalLoad === false) {
      setFetch(value);
    }
  };

  const handlePageState = (value: number) => {
    setPageState(value);
  };

  const handleAdasSearch = async (data: []) => {
    if (data.length !== 0) {
      const tableSearch = formatCompletedTable(data);
      setTableData([...tableSearch]);
    } else {
      setTableData([]);
    }
  };

  const handleCsvDownload = async (tableName: string) => {
    setIsLoading(true);
    try {
      await tableCsvDownload(tableName);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const getData = async () => {
      if (finalLoad === false && fetch) {
        setIsLoading(true);
        const params =
          Object.keys(pagination).length !== 0
            ? { PaginationKey: pagination }
            : {};
        try {
          const { data } = await adas.post('/ConfirmedCalibrations', params);
          setTotalItems(data.TotalItems ? data.TotalItems : totalItems);
          const aux = formatCompletedTable(data.Items);
          const array = [...tableData, ...aux];
          if (data.PaginationKey) {
            setPagination(data.PaginationKey);
          } else {
            setPagination({} as IPaginationKey);
            setFinalLoad(true);
          }
          setTableData(array);
        } catch (err: any) {
          setTableData([]);
          setFinalLoad(true);
          if (err.response && err.response.status === 401) {
            setIsAuthorized(false);
            setErrorMessage('You are not authorized to perform this action');
          }
        } finally {
          setFetch(false);
          setIsLoading(false);
        }
      }
    };
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const components = {
    ExpandButton({ dataIndex, ...rest }: { dataIndex: number }) {
      const row = tableData[dataIndex];
      const tags = !!(row && row.Tags);
      return <CustomExpandButton tags={tags} rest={rest} />;
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      {isLoading && (
        <LoaderDiv>
          <CircularProgress
            size={24}
            style={{ color: 'var(--primary-color)' }}
          />
        </LoaderDiv>
      )}
      {errorMessage !== '' && (
        <RedAlert>
          <CloseAlertButton onClick={() => setErrorMessage('')}>
            &times;
          </CloseAlertButton>
          {errorMessage}
        </RedAlert>
      )}
      {successMessage !== '' && (
        <GreenAlert>
          <CloseAlertButton onClick={() => setSuccessMessage('')}>
            &times;
          </CloseAlertButton>
          {successMessage}
        </GreenAlert>
      )}
      {isAuthorized && (
        <MuiThemeProvider theme={themeMuiTable}>
          <MUIDataTable
            columns={[
              ...cols,
              {
                label: 'Download',
                name: 'Download',
                options: {
                  filter: false,
                  print: false,
                  download: false,
                  customBodyRender: (_, { rowData }) => {
                    return (
                      <DownloadEvidence
                        rowData={rowData}
                        tableData={tableData}
                        setErrorMessage={handleErrorMessage}
                        setIsLoading={handleLoading}
                      />
                    );
                  },
                },
              },
            ]}
            data={tableData}
            title=""
            components={components}
            options={{
              ...commonOptions,
              count: totalItems,
              tableBodyMaxHeight: `${tableFinalHeight}px`,
              rowsPerPage,
              responsive: 'vertical',
              renderExpandableRow: (rowData: string[]) => {
                return <ExpandableCommentsTable rowData={rowData} index={7} />;
              },
              customSearchRender: (searchText, _, hideSearch) => {
                return (
                  <TableSearch
                    tableName={TableNamesEnum.CompletedCalibrations}
                    setFetch={handleFetch}
                    hideSearch={hideSearch}
                    setTableData={handleAdasSearch}
                    handleLoading={handleLoading}
                    handleErrorMessage={handleErrorMessage}
                  />
                );
              },
              customFooter: (
                rowCount: number,
                page: number,
                _: number,
                changeRowsPerPage: (pageS: React.Key) => void,
                changePage: (newPage: number) => void,
                textLabels: Partial<MUIDataTableTextLabels>,
              ) => {
                return (
                  <CustomTableFooter
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    pageS={pageState}
                    tableDataLength={tableData.length}
                    setFetch={handleFetch}
                    setPageState={handlePageState}
                    onChange={changePage}
                    textLabels={textLabels}
                  />
                );
              },
              onDownload: () => {
                handleCsvDownload(TableNamesEnum.CompletedCalibrations);
                return false;
              },
            }}
          />
        </MuiThemeProvider>
      )}
    </div>
  );
};

export default CompletedRecalibrationsTable;
