/*
  The idea is to resize the table based on the row height,
  since each table have different amount of columns and each one,
  can ocuppy more space than the other and it also depends on the
  width of the page, this was the best solution to do this dynamically
  and without too many if's
*/

interface AutoSizeReturn {
  tableFinalHeight: number;
  rowsPerPage: number;
}

const tableAutoSize = (height: number, tr: Element): AutoSizeReturn => {
  const trHeight = tr ? tr.scrollHeight : 0;
  const table = height - 270 - 56 - 53; // 56 = tableHeader ~ 53 = tableFooter
  const tableFinalHeight = table < trHeight ? trHeight : table;
  const rowsPerPage =
    Math.floor(tableFinalHeight / trHeight) - 1 !== 0
      ? Math.floor(tableFinalHeight / trHeight) - 1
      : 2;

  return { rowsPerPage, tableFinalHeight };
};

export default tableAutoSize;
