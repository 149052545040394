import React from 'react';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import MuiTablePagination from '@material-ui/core/TablePagination';
import { MUIDataTableTextLabels } from 'mui-datatables';

interface Props {
  pageS: number;
  count: number;
  textLabels: Partial<MUIDataTableTextLabels>;
  rowsPerPage: number;
  tableDataLength: number;
  onChange: (page: number) => void;
  setPageState: (page: number) => void;
  setFetch: (value: boolean) => void;
}

const CustomTableFooter: React.FC<Props> = ({
  pageS,
  textLabels,
  count,
  rowsPerPage,
  tableDataLength,
  onChange,
  setPageState,
  setFetch,
  // handlePageChange,
}) => {
  const footerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 24px 0px 24px',
  };

  const handlePageChange = (actualPage: number) => {
    setPageState(actualPage);
    if (tableDataLength % rowsPerPage === 0) {
      if (actualPage === Math.floor(tableDataLength / rowsPerPage) - 1) {
        setFetch(true);
      }
    } else if (actualPage === Math.floor(tableDataLength / rowsPerPage)) {
      setFetch(true);
    } else {
      setFetch(false);
    }
  };

  return (
    <TableFooter>
      <TableRow>
        <TableCell style={footerStyle} colSpan={1000}>
          <MuiTablePagination
            rowsPerPage={rowsPerPage}
            component="div"
            count={count}
            page={pageS}
            labelDisplayedRows={({ page }) => {
              return `Total items: ${count} - Page: ${page + 1}`;
            }}
            backIconButtonProps={{
              'aria-label': textLabels.pagination?.previous,
            }}
            nextIconButtonProps={{
              'aria-label': textLabels.pagination?.next,
            }}
            rowsPerPageOptions={[]}
            onChangePage={(_, page: number) => {
              onChange(page);
              handlePageChange(page);
            }}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default CustomTableFooter;
