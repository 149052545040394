import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Flag from 'react-flagkit';
import jwt_decode from 'jwt-decode';

import {
  AiOutlineDown,
  AiOutlineExclamationCircle,
  AiOutlineFundProjectionScreen,
  AiOutlineMenu,
  AiOutlineCheckCircle,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';

import {
  Container,
  User,
  Picture,
  Country,
  Dropdown,
  Countries,
  Select,
  UserMenu,
  List,
  MenuButton,
  FlagLoginContainer,
  MenuLogoContainer,
} from './styles';

import { useAuth } from '../../hooks/auth';
import { Logo } from '../NavigationBar/styles';

export interface ITokenPayload {
  email: string;
}

interface Props {
  country: string;
  setCountry: Function;
}

const Header: React.FC<Props> = ({ country, setCountry }) => {
  const { signOut, data } = useAuth();
  const [menu, setMenu] = useState<boolean>(false);
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [userMenu, setUserMenu] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');

  function CloseMenuOutside(
    ref: React.MutableRefObject<HTMLDivElement | null>,
  ) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenu(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  CloseMenuOutside(wrapperRef);

  useEffect(() => {
    const decodedToken: ITokenPayload = jwt_decode(data.id_token);
    setUserEmail(decodedToken.email);
  }, [data]);

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleUserMenu = () => {
    setUserMenu(!userMenu);
  };

  return (
    <Container show={menu}>
      <MenuLogoContainer show={menu}>
        <MenuButton ref={wrapperRef} onClick={() => setMenu(!menu)}>
          <AiOutlineMenu />

          <List show={menu}>
            <NavLink
              exact
              className="menuLink"
              activeClassName="activePage"
              to="/"
              activeStyle={{ color: 'var(--primary-color)' }}
            >
              <AiOutlineFundProjectionScreen />
              <span>Missed Recalibrations</span>
            </NavLink>
            <NavLink
              className="menuLink"
              activeClassName="activePage"
              to="/issues"
              activeStyle={{ color: 'var(--primary-color)' }}
            >
              <AiOutlineExclamationCircle />
              <span>Potential Issues</span>
            </NavLink>
            <NavLink
              className="menuLink"
              activeClassName="activePage"
              to="/completed"
              activeStyle={{ color: 'var(--primary-color)' }}
            >
              <AiOutlineCheckCircle />
              <span>Completed Recalibrations</span>
            </NavLink>
            <NavLink
              className="menuLink"
              activeClassName="activePage"
              to="/mismatch"
              activeStyle={{ color: 'var(--primary-color)' }}
            >
              <AiOutlineQuestionCircle />
              <span>Data mismatch</span>
            </NavLink>
          </List>
        </MenuButton>

        <Logo>
          <h1>Recalibration Audit</h1>
        </Logo>
      </MenuLogoContainer>
      <FlagLoginContainer>
        <Country>
          <Select onClick={handleDropdown}>
            <span>{country}</span>
            <Flag country={country} size={20} />
          </Select>

          <Dropdown show={dropdown}>
            <Countries
              onClick={() => {
                setCountry('DK');
                handleDropdown();
              }}
            >
              <span>Denmark</span>
              <Flag country="DK" size={20} />
            </Countries>

            {/* <Countries
              onClick={() => {
                setCountry('BE');
                handleDropdown();
              }}
            >
              <span>Belgium</span>
              <Flag country="BE" size={20} />
            </Countries>

            <Countries
              onClick={() => {
                setCountry('GB');
                handleDropdown();
              }}
            >
              <span>United Kingdom</span>
              <Flag country="GB" size={20} />
            </Countries>

            <Countries
              onClick={() => {
                setCountry('DE');
                handleDropdown();
              }}
            >
              <span>Germany</span>
              <Flag country="DE" size={20} />
            </Countries> */}
          </Dropdown>
        </Country>

        <User onClick={handleUserMenu}>
          <Picture />
          <h1>{userEmail}</h1>
          <AiOutlineDown />
          <UserMenu show={userMenu} onClick={signOut}>
            Logout
          </UserMenu>
        </User>
      </FlagLoginContainer>
    </Container>
  );
};

export default Header;
