import styled, { css } from 'styled-components';

interface ILogout {
  show: boolean;
}

interface IContainer {
  show: boolean;
}

interface IList {
  show: boolean;
}

export const Container = styled.nav<IContainer>`
  @media (min-width: 320px) and (max-width: 480px) {
    position: absolute;

    background: var(--primary-color);

    display: flex;
    flex-direction: ${({ show }) => (show ? 'column' : 'row')};
    align-items: center;
    justify-content: ${({ show }) => (show ? 'flex-start' : 'space-between')};

    width: 100%;
    height: ${({ show }) => (show ? '140px' : '60px')};

    padding: ${({ show }) => (show ? '12px 0 0 0' : '0 24px')};

    z-index: 1000;
  }

  @media (min-width: 481px) and (max-width: 719px) {
    display: none;
  }

  @media (min-width: 720px) and (max-width: 1023px) {
    display: none;
  }

  width: 286px;

  background: var(--navigation-background);

  height: 100%;
`;

export const Logo = styled.div`
  @media (min-width: 320px) and (max-width: 600px) {
    display: none;
  }

  justify-content: center;
  align-items: center;

  margin-left: 30px;

  h1 {
    font-size: 30px;
    font-weight: 700;
    color: var(--primary-color);
  }
`;

export const List = styled.div<IList>`
  @media (min-width: 320px) and (max-width: 1024px) {
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;

    padding: 0;

    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      margin-bottom: 12px;

      font-size: 20px;
      font-weight: 500;
      color: var(--navigation-background);

      svg {
        display: none;
      }

      &:first-child {
        margin-top: 12px;
      }
    }
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;

    padding: 0 38px;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 48px;

      font-size: 18px;
      font-weight: 500;
      color: var(--font-color);

      transition: color 0.3s;

      svg {
        width: 24px;
        height: 24px;
      }

      &:hover {
        color: var(--primary-color);
      }
    }
  }
`;

const buttonsStyle = css`
  @media (min-width: 320px) and (max-width: 480px) {
    color: var(--navigation-background);

    svg {
      width: 32px;
      height: 32px;
    }
  }

  @media (min-width: 481px) {
    display: none;
  }
`;

export const MenuButton = styled.div`
  ${buttonsStyle}
`;

export const LogoutButton = styled.div<ILogout>`
  ${buttonsStyle};

  display: ${({ show }) => show && 'none'};
`;
