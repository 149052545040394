import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }


  .menuLink {
    display: flex!important;
    align-items: center!important;
  }

  .menuLink span{
    margin-left: 5px;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    background: #F5F5F5;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 16px Roboto, sans-serif;
  }

  button {
    cursor: pointer;
  }

  :root {
    --navigation-background: #ffffff;
    --font-color: #707070;
    --primary-color: #3BCCF6;
    --white: #ffffff;
    --red: #F66459;
    --green: #6BBD6E;
    --warning: #ffaa2c;
  }

  ul, li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }
`;
