import { Button } from '@material-ui/core';
import React from 'react';
import evidenceDownload from '../../utils/evidenceDownload';
import { useStyles } from '../UI/material.styles';

interface Props {
  rowData: string[];
  tableData: any;
  setErrorMessage: (value: string) => void;
  setIsLoading: (value: boolean) => void;
}

const DownloadEvidence: React.FC<Props> = ({
  rowData,
  tableData,
  setErrorMessage,
  setIsLoading,
}) => {
  const classes = useStyles();
  const handleDownloadEvidence = async (vin: string) => {
    setErrorMessage('');
    setIsLoading(true);
    const findEvidence = tableData.find((data: any) => data.VIN === vin);
    if (findEvidence?.Evidence) {
      try {
        await evidenceDownload(findEvidence?.Evidence, vin);
      } catch (error) {
        setErrorMessage(error.message);
      }
    } else {
      setErrorMessage('Evidence not found!');
    }
    setIsLoading(false);
  };

  return (
    <Button
      onClick={() => {
        handleDownloadEvidence(rowData[0]);
      }}
      variant="contained"
      className={classes.button}
    >
      Download
    </Button>
  );
};

export default DownloadEvidence;
