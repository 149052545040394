import React from 'react';
import Loader from 'react-loader-spinner';

import { Container } from './styles';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const SignIn: React.FC = () => {
  return (
    <Container>
      <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
    </Container>
  );
};

export default SignIn;
