import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import qs from 'qs';

import cognito from '../services/cognito';

// eslint-disable-next-line @typescript-eslint/no-var-requires
require('dotenv').config();

interface AuthState {
  access_token: string;
  id_token: string;
  refresh_token: string;
}

interface AuthContextData {
  signIn(): void;
  signOut(): void;
  data: AuthState;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const access_token = localStorage.getItem('@adas:access_token');
    const id_token = localStorage.getItem('@adas:id_token');
    const refresh_token = localStorage.getItem('@adas:refresh_token');

    if (access_token && id_token && refresh_token) {
      return { access_token, id_token, refresh_token };
    }

    return {} as AuthState;
  });

  const getToken = async () => {
    const params = {
      grant_type: 'authorization_code',
      client_id: process.env.REACT_APP_client_id,
      code: window.location.search.split('?code=')[1],
      redirect_uri: process.env.REACT_APP_redirect_uri,
    };

    try {
      const response = await cognito.post(
        'oauth2/token',
        qs.stringify(params),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );

      const { access_token, id_token, refresh_token } = response.data;

      setData({ access_token, id_token, refresh_token });

      localStorage.setItem('@adas:access_token', access_token);
      localStorage.setItem('@adas:id_token', id_token);
      localStorage.setItem('@adas:refresh_token', refresh_token);
    } catch (err) {
      window.location.href = `${process.env.REACT_APP_base_url}/login?response_type=code&client_id=${process.env.REACT_APP_client_id}&redirect_uri=${process.env.REACT_APP_redirect_uri}&scope=openid`;
    }
  };

  useEffect(() => {
    if (!data.access_token) {
      getToken();
    }
  }, [data.access_token]);

  const signIn = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_base_url}/login?response_type=code&client_id=${process.env.REACT_APP_client_id}&redirect_uri=${process.env.REACT_APP_redirect_uri}&scope=openid`;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@adas:access_token');
    localStorage.removeItem('@adas:id_token');
    localStorage.removeItem('@adas:refresh_token');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider value={{ signIn, signOut, data }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
