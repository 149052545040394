import styled from 'styled-components';

export const RedAlert = styled.div`
  padding: 20px;
  background-color: var(--red);
  color: white;

  text-align: center;
`;

export const GreenAlert = styled.div`
  padding: 20px;
  background-color: var(--green);
  color: white;

  text-align: center;
`;

export const WarningAlert = styled.div`
  padding: 20px;
  background-color: var(--warning);
  color: white;

  text-align: center;
`;

export const CloseAlertButton = styled.span`
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: black;
  }
`;
