import React, { useState, useEffect } from 'react';
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableTextLabels,
} from 'mui-datatables';

import { Button, CircularProgress, MuiThemeProvider } from '@material-ui/core';

import adas from '../../../services/adas';

import CustomTableFooter from '../../TableFooter';
import { IPaginationKey } from '../PotentialIssuesTable';

import ModalComment, { ModalItemData } from '../../ModalComment';
import { LoaderDiv } from '../../Loader/styles';
import { themeMuiTable, useStyles } from '../material.styles';
import { CloseAlertButton, RedAlert, GreenAlert } from '../../Alerts/styles';
import {
  formatMissedTable,
  IMissedTableData,
} from '../../../utils/formatMissedTable';
import TableSearch from '../../TableSearch';
import TableNamesEnum from '../../../utils/enum/tables.enum';
import tableCsvDownload from '../../../utils/tableCsvDownload';
import tableAutoSize from '../../../utils/tableAutoSize';
import { MissedTableContainer } from './styles';
import { Tags } from '../../../utils/formatPotentialTable';
import CustomSyncButton from '../../CustomSyncButton';
import commonOptions from '../commonOptions';
import ExpandableCommentsTable from '../../ExpandableCommentsTable';
import CustomExpandButton from '../../CustomExpandButton';

export const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const cols: MUIDataTableColumnDef[] = [
  {
    label: 'VIN Number',
    name: 'VIN',
    options: {
      filter: true,
      print: true,
    },
  },
  {
    label: 'Product Number',
    name: 'ProductsFound',
    options: {
      filter: true,
      print: true,
    },
  },
  {
    label: 'Job Date',
    name: 'GSHTimestamp',
    options: {
      filter: false,
      print: true,
      customBodyRender: value => {
        if (value) {
          const time = new Date(+value);

          return time.toLocaleString(['eu-US'], dateFormatOptions);
        }
        return 'Null';
      },
    },
  },
  {
    name: 'Tags',
    options: {
      display: false,
      viewColumns: false,
      filter: false,
    },
  },
  {
    name: 'GSHJobID',
    label: 'Job ID',
    options: {
      filter: true,
      print: true,
      customBodyRender: value => {
        if (!value) {
          return `---`;
        }
        return value;
      },
    },
  },
  {
    name: 'UUID',
    options: {
      display: false,
      viewColumns: false,
      filter: false,
    },
  },
];

interface Props {
  title: string;
}

const MissCalibrationTable: React.FC<Props> = () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState<IMissedTableData[]>([]);
  const [pagination, setPagination] = useState<IPaginationKey>(
    {} as IPaginationKey,
  );
  const [totalItems, setTotalItems] = useState(0);
  const [finalLoad, setFinalLoad] = useState(false);
  const [pageState, setPageState] = useState(0);
  const [fetch, setFetch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [itemData, setItemData] = useState<ModalItemData>({} as ModalItemData);
  const [overrideList, setOverrideList] = useState<string[]>([]);

  const height = window.innerHeight;
  const tr = document.getElementsByClassName('MuiTableRow-root')[1];
  const { tableFinalHeight, rowsPerPage } = tableAutoSize(height, tr);

  const handleSuccessMessage = (value: string) => {
    setSuccessMessage(value);
  };

  const handleErrorMessage = (value: string) => {
    setErrorMessage(value);
  };

  const handleTableData = (value: IMissedTableData[]) => {
    setTableData(value);
  };

  const handleLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const handleOpen = (value: boolean) => {
    setOpen(value);
  };

  const handleFetch = (value: boolean) => {
    if (finalLoad === false) {
      setFetch(value);
    }
  };

  const handleOverrideList = (value: string) => {
    setOverrideList([...overrideList, value]);
  };

  const handlePageState = (value: number) => {
    setPageState(value);
  };

  const handleItemUpdate = (value: Tags, index: number) => {
    const itemsList = [...tableData];
    let item = tableData[index];
    if (item && item.Tags) {
      item.Tags.push(value);
    } else {
      item = {
        ...item,
        Tags: [{ ...value }],
      };
    }
    itemsList[index] = item;
    setTableData([...itemsList]);
    if (value.tag === 'SOLVED') {
      setSuccessMessage(`${item.VIN} successfully marked as solved`);
    } else {
      setSuccessMessage(`Successfully add comment on VIN ${item.VIN}`);
    }
  };

  const handleAdasSearch = async (data: []) => {
    if (data.length !== 0) {
      const tableSearch = formatMissedTable(data);
      setTableData([...tableSearch]);
    } else {
      setTableData([]);
    }
  };

  const handleCsvDownload = async (tableName: string) => {
    setIsLoading(true);
    try {
      await tableCsvDownload(tableName);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const getData = async () => {
      if (finalLoad === false && fetch) {
        setIsLoading(true);
        const params =
          Object.keys(pagination).length !== 0
            ? { PaginationKey: pagination }
            : {};
        try {
          const { data } = await adas.post('/missedRecalibrations', params);
          const aux = formatMissedTable(data.Items);
          setTotalItems(data.TotalItems ? data.TotalItems : totalItems);
          const array = [...tableData, ...aux];

          if (data.PaginationKey) {
            setPagination(data.PaginationKey);
          } else {
            setPagination({} as IPaginationKey);
            setFinalLoad(true);
          }

          setTableData(array);
          setIsAuthorized(true);
        } catch (err: any) {
          setTableData([]);
          setFinalLoad(true);
          if (err.response && err.response.status === 401) {
            setIsAuthorized(false);
            setErrorMessage('You are not authorized to perform this action');
          }
        } finally {
          setFetch(false);
          setIsLoading(false);
        }
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const components = {
    ExpandButton({ dataIndex, ...rest }: { dataIndex: number }) {
      const row = tableData[dataIndex];
      const tags = !!(row && row.Tags);
      return <CustomExpandButton tags={tags} rest={rest} />;
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      {isLoading && (
        <LoaderDiv>
          <CircularProgress
            size={24}
            style={{ color: 'var(--primary-color)' }}
          />
        </LoaderDiv>
      )}
      <ModalComment
        open={open}
        setOpen={handleOpen}
        itemData={itemData}
        setOverrideList={handleOverrideList}
        setUpdateList={handleItemUpdate}
      />
      {errorMessage !== '' && (
        <RedAlert>
          <CloseAlertButton onClick={() => setErrorMessage('')}>
            &times;
          </CloseAlertButton>
          {errorMessage}
        </RedAlert>
      )}
      {successMessage !== '' && (
        <GreenAlert>
          <CloseAlertButton onClick={() => setSuccessMessage('')}>
            &times;
          </CloseAlertButton>
          {successMessage}
        </GreenAlert>
      )}
      {isAuthorized && (
        <MuiThemeProvider theme={themeMuiTable}>
          <MissedTableContainer>
            <MUIDataTable
              columns={[
                ...cols,
                {
                  label: 'HG Calibration Type',
                  name: 'Calibration',
                  options: {
                    filter: false,
                    print: true,
                    customBodyRender: (_, { rowData }) => {
                      const type = rowData[6] ? rowData[6] : '---';
                      return (
                        <CustomSyncButton
                          rowData={rowData}
                          UUID={rowData[5]}
                          type={type}
                          tableData={tableData}
                          setErrorMessage={handleErrorMessage}
                          setSuccessMessage={handleSuccessMessage}
                          setTableData={handleTableData}
                          setLoading={handleLoading}
                        />
                      );
                    },
                  },
                },
                {
                  label: 'Action',
                  name: 'Action',
                  options: {
                    filter: false,
                    print: false,
                    download: false,
                    customBodyRender: (_, { rowData }) => {
                      const vin = rowData[0];
                      const UUID = rowData[5];
                      return (
                        <Button
                          onClick={() => {
                            handleOpen(true);
                            const idx = tableData.findIndex(
                              data => data.VIN === vin,
                            );
                            const data: ModalItemData = {
                              UUID,
                              vin,
                              index: idx,
                            };
                            setItemData(data);
                          }}
                          disabled={
                            !!overrideList.find(override => override === vin)
                          }
                          variant="contained"
                          className={classes.button}
                        >
                          {overrideList.find(override => override === vin)
                            ? 'Solved'
                            : 'Add comment'}
                        </Button>
                      );
                    },
                  },
                },
              ]}
              data={tableData}
              title=""
              components={components}
              options={{
                ...commonOptions,
                count: totalItems,
                rowsPerPage,
                tableBodyMaxHeight: `${tableFinalHeight}px`,
                responsive: 'vertical',
                renderExpandableRow: (rowData: string[]) => {
                  return (
                    <ExpandableCommentsTable rowData={rowData} index={3} />
                  );
                },
                customSearchRender: (searchText, _, hideSearch) => {
                  return (
                    <TableSearch
                      tableName={TableNamesEnum.MissedRecalibrations}
                      setFetch={handleFetch}
                      hideSearch={hideSearch}
                      setTableData={handleAdasSearch}
                      handleLoading={handleLoading}
                      handleErrorMessage={handleErrorMessage}
                    />
                  );
                },
                customFooter: (
                  rowCount: number,
                  page: number,
                  _: number,
                  changeRowsPerPage: (pageS: React.Key) => void,
                  changePage: (newPage: number) => void,
                  textLabels: Partial<MUIDataTableTextLabels>,
                ) => {
                  return (
                    <CustomTableFooter
                      count={totalItems}
                      rowsPerPage={rowsPerPage}
                      pageS={pageState}
                      tableDataLength={tableData.length}
                      textLabels={textLabels}
                      setFetch={handleFetch}
                      setPageState={handlePageState}
                      onChange={changePage}
                    />
                  );
                },
                onDownload: () => {
                  handleCsvDownload(TableNamesEnum.MissedRecalibrations);
                  return false;
                },
              }}
            />
          </MissedTableContainer>
        </MuiThemeProvider>
      )}
    </div>
  );
};

export default MissCalibrationTable;
